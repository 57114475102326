import {
	GridContainer,
	GridItem,
	GridRow,
	TabPanel,
	Tabs as TabsFuse,
	gridConfig,
} from '@tvg/fuse-design';
import React from 'react';
import styled from 'styled-components';
import { RequestParams } from '../../utils/types';
import { Branded, ComponentRenderer, HydratableRendering } from '../types';
import { TabContentFactory } from './tab-content-factory';
import { TabsComponent } from './types';

export const tabsSchemaId = 'https://theverygroup.com/dcx-templating/modules/tabs';

const StyledGridContainer = styled(GridContainer)`
	@media only screen and (max-width: ${gridConfig.m.maxWidth}) {
		&& {
			padding: 0;
		}
	}
`;

export const Tabs = ({
	tabListAriaLabel,
	tabs,
	brand,
	params,
}: Branded<TabsComponent>): JSX.Element => {
	return (
		<StyledGridContainer>
			<GridRow>
				<GridItem xxs="12">
					<TabsFuse tabListAriaLabel={tabListAriaLabel || 'Content Tabs'}>
						{tabs.map(({ label, content }) => (
							<TabPanel label={label}>
								<TabContentFactory content={content} brand={brand} params={params} />
							</TabPanel>
						))}
					</TabsFuse>
				</GridItem>
			</GridRow>
		</StyledGridContainer>
	);
};

export const tabsRenderer: ComponentRenderer<TabsComponent> = (
	props: TabsComponent,
	brand: string,
	params?: RequestParams,
): HydratableRendering => ({
	component: <Tabs {...props} brand={brand} params={params} />,
	props: { ...props, brand, params },
	reference: 'Tabs',
	enableMetaSchema: true,
});
