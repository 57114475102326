import eventBus from '@sd-utility/eventbus';
import { Button, Modal as ModalFuse, TextBlock, gridConfig } from '@tvg/fuse-design';
import { IconChevronRight } from '@tvg/fuse-icons';
import { sanitize } from 'isomorphic-dompurify';
import { marked } from 'marked';
import { gfmHeadingId } from 'marked-gfm-heading-id';
import React from 'react';
import styled from 'styled-components';
import buildGoogleAnalyticsTag from '../../utils/buildGoogleAnalyticsTag';
import buttonClickHandler from '../../utils/buttonClickHandler';
import { ComponentRenderer, HydratableRendering } from '../types';
import { ModalComponent } from './types';

export const modalSchemaId = 'https://theverygroup.com/dcx-templating/modules/modal';

const backgroundConfig: { [key: string]: string } = {
	'Brand Light': 'brandLight',
	'Brand Mid': 'brandMid',
	Grey: 'grey',
	Black: 'black',
};

const StyledButtonContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	padding: 0 var(--spacing-global-space5);
	margin-bottom: var(--spacing-global-space5);

	@media only screen and (min-width: ${gridConfig.m.minWidth}) {
		padding: 0 var(--spacing-global-space6);
		margin-bottom: var(--spacing-global-space6);
	}

	@media only screen and (min-width: ${gridConfig.l.minWidth}) {
		padding: 0 var(--spacing-global-space7);
		margin-bottom: var(--spacing-global-space7);
	}

	> button,
	> a {
		@media only screen and (max-width: ${gridConfig.m.minWidth}) {
			width: 100%;
		}
	}
`;

const StyledTextBlock = styled(TextBlock)`
	&& {
		> *:last-child {
			margin-bottom: 0;
		}
	}
`;

marked.use(
	gfmHeadingId({
		prefix: 'heading__',
	}),
);

export const Modal = ({ modalKey, header, content }: ModalComponent): JSX.Element => {
	const [modalOpen, setModalOpen] = React.useState<boolean>(false);

	const closeModalHandler = () => {
		setModalOpen(false);
		eventBus.publish('modal/close', modalKey);
	};

	eventBus.subscribe('modal/open', (eventData: string) => {
		eventData === modalKey && setModalOpen(true);
	});

	const modalButtonClickHandler = (value: string) => {
		setModalOpen(false);
		eventBus.publish('modal/close', modalKey);
		buttonClickHandler(value);
	};

	return (
		<>
			<ModalFuse
				className={modalKey.replace('modal/', 'modal-')}
				headerIcon={header.icon}
				title={header.title}
				hasPadding={false}
				srLabelCloseButton="Close modal"
				zIndex="99" // Setting this value initially to work with Search and Menu which have z-index of 100
				open={modalOpen}
				onClose={() => closeModalHandler()}
			>
				{content.headline && content.headline.headlineText && (
					<StyledTextBlock
						background={backgroundConfig[content.headline.background]}
						dangerouslySetInnerHTML={{
							__html: sanitize(marked.parse(content.headline.headlineText) as string),
						}}
					/>
				)}

				<StyledTextBlock
					dangerouslySetInnerHTML={{
						__html: sanitize(marked.parse(content.mainContent.mainContentText) as string),
					}}
				/>

				{content.cta && (content.cta.url || content.cta.publishEvent) && (
					<StyledButtonContainer>
						<Button
							href={content.cta.url}
							iconRight
							icon={<IconChevronRight />}
							onClick={
								content.cta.publishEvent &&
								(() => modalButtonClickHandler(`${content?.cta?.publishEvent}`))
							}
							data-analytics-id={buildGoogleAnalyticsTag(
								'Modal',
								`${header.title} - ${content.cta.text}`,
							)}
						>
							{content.cta.text}
						</Button>
					</StyledButtonContainer>
				)}
			</ModalFuse>
		</>
	);
};

export const modalRenderer: ComponentRenderer<ModalComponent> = (
	props: ModalComponent,
): HydratableRendering => ({
	component: <Modal {...props} />,
	props,
	reference: 'Modal',
});
