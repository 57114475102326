import {
	GridItem,
	GridRow,
	SpacingWrapper,
	TextBlock as TextBlockFuse,
	gridConfig,
} from '@tvg/fuse-design';
import React from 'react';
import styled from 'styled-components';
import richTextEditor from '../../utils/rich-text-editor/richTextEditor';
import { RequestParams } from '../../utils/types';
import { StyledPageContentGridContainer } from '../shared/styles';
import { Branded, ComponentRenderer, HydratableRendering } from '../types';
import backgroundConfig from './backgroundConfig';
import { TextBlockComponent } from './types';

export const textBlockSchemaId = 'https://theverygroup.com/dcx-templating/modules/text-block';

// Equivalent to 10 columns. Required to keep text block background colour full width but content inside to reduce to 10 columns.
const customWidth = (10 / 12) * 100;

const StyledGridItem = styled(GridItem)`
	&& {
		.fuse-text-block {
			> * {
				@media only screen and (min-width: ${gridConfig.l.minWidth}) {
					max-width: ${customWidth}%;
				}
			}

			.fuse-text-block--center-align {
				margin-right: auto;
				margin-left: auto;
			}
		}
	}
`;

export const TextBlock = ({
	background,
	blackBorder = false,
	gridWrapper = true,
	paddingTop = false,
	paddingBottom = false,
	paddingLeft = false,
	paddingRight = false,
	text,
	params,
}: Branded<TextBlockComponent>): JSX.Element => {
	const isStandardPageContent = params?.prefix === 'standard';

	const TextBlock = (
		<TextBlockFuse
			background={background && backgroundConfig[background].propValue}
			paddingTop={paddingTop}
			paddingBottom={paddingBottom}
			paddingLeft={paddingLeft}
			paddingRight={paddingRight}
			blackBorder={blackBorder}
			dangerouslySetInnerHTML={{
				__html: richTextEditor(text, background),
			}}
		/>
	);

	return gridWrapper ? (
		<SpacingWrapper marginBottom="l">
			<StyledPageContentGridContainer isStandardPageContent={isStandardPageContent}>
				<GridRow>
					<StyledGridItem xxs="12">{TextBlock}</StyledGridItem>
				</GridRow>
			</StyledPageContentGridContainer>
		</SpacingWrapper>
	) : (
		TextBlock
	);
};

export const textBlockRenderer: ComponentRenderer<TextBlockComponent> = (
	props: TextBlockComponent,
	brand: string,
	params?: RequestParams,
): HydratableRendering => ({
	component: <TextBlock {...props} brand={brand} params={params} />,
	props: { ...props, brand },
	reference: 'TextBlock',
});
